$(document).ready(() => {
    console.log('jquery ready')

    // toggle all klappbox content
    $('.klappbox-content').toggle(0)

    $(".klappbox").each((i, e) => {
        console.log(e);
        $(e).find(".klappbox-header").on('click', () => {
            $(e).find(".klappbox-content").toggle(500);
            $(e).find('.klappbox-header .icon').toggleClass('rotate');
        })
    })
})